import { BrowserRouter } from 'react-router-dom';
import React, { useState } from 'react';


import { OneTrustProvider } from 'services/OneTrustProvider';

import useSkipOnboarding from './hooks/useSkipOnboarding';
import useAppInit from './hooks/useAppInit';

import InitTestania from 'pages/GeneralPages/InitTestania';

import StartAnalyticsContainer from 'containers/OtherContainers/StartAnalyticsContainer';

import GuaranteeModal from 'modals/GuaranteeModal';

import ReleaseConfig from 'components/ReleaseConfig';
import ErrorMessage from 'components/ErrorMessage';
import RootHeader from './components/RootHeader';

import Routers from './router';

// @ts-ignore
export const Context = React.createContext();

const App = () => {
  const flowFromLS = localStorage.getItem('userGoal');
  const [flow, setFlow] = useState(flowFromLS);

  const { onSkip } = useSkipOnboarding();
  useAppInit();

  return (
    <OneTrustProvider>
      <Context.Provider value={[flow, setFlow]}>
        <BrowserRouter>
          <ErrorMessage />
          <GuaranteeModal />
          <RootHeader onSkip={onSkip} />
          <ReleaseConfig />
          <React.Suspense fallback={null}>
            {/* {flow && <InitTestania />}*/}
            <InitTestania />
            <Routers />
            <StartAnalyticsContainer />
          </React.Suspense>
        </BrowserRouter>
      </Context.Provider>
    </OneTrustProvider>
  );
};

export default App;
